<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">

          <h2 class="brand-text text-primary ml-1">
            TEAM <vuexy-logo /> SEVEN
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="ID"
            >
              <validation-provider
                #default="{ errors }"
                name="ID"
                rules="required"
              >
                <b-form-input
                  id="login-email"
                  v-model="userid"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="ID"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="text-info text-center font-small-2">
              IP: {{ this.ip }}
            </div>
            <div class="text-info text-center font-small-2">
              <img
                class="flag"
                style="width: 20px;"
                :src="`${flagUrl}`"
              > {{ this.countryCity }}
            </div>
            <!-- submit button -->
            <b-button
              variant="primary"
              style="margin-top: 40px; margin-bottom: 20px;"
              type="submit"
              block
              @click="validationForm"
            >
              LOG IN V1
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { LOGIN } from '@/store/auth/action'
import { SIGNIN_LOGS } from '@/store/logs/action'
import { createNamespacedHelpers } from 'vuex'
import axios from 'axios'
import Bowser from 'bowser'
import { FETCH_SET_IP_ADMIN, FETCH_SET_IP_BLOCK } from '@/store/settings/action'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import crypto from 'crypto'

const settingsStore = createNamespacedHelpers('settingsStore')
const authStore = createNamespacedHelpers('authStore')
const logStore = createNamespacedHelpers('logStore')

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userid: 'admin1',
      password: '1234',
      // userid: 'perdonis',
      // password: 'vpfmthsK7*',
      // userid: '',
      // password: '',
      code: '',
      status: '',
      // validation rules
      required,
      // client info
      site: 'GAEGGUL', // this.$site,
      flagUrl: '',
      ip: '',
      browser: '',
      browserVer: '',
      os: '',
      osVer: '',
      platform: '',
      networkCo: '',
      networkType: '',
      asn: '',
      route: '',
      country: '',
      city: '',
      latitude: '',
      longitude: '',
      countryCity: '',
    }
  },
  mounted() {
    this.getIpClient()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...settingsStore.mapGetters({
      fetchSetIpAdmin: 'fetchSetIpAdmin',
      fetchSetIpBlock: 'fetchSetIpBlock',
    }),
  },
  methods: {
    ...authStore.mapActions({
      login: LOGIN,
    }),
    ...logStore.mapActions({
      $SigninLogs: SIGNIN_LOGS,
    }),
    ...settingsStore.mapActions({
      $fetchSetIpAdmin: FETCH_SET_IP_ADMIN,
      $fetchSetIpBlock: FETCH_SET_IP_BLOCK,
    }),
    async fetchData() {
      // await this.$fetchSetIpAdmin({
      //   site: mySite,
      // }).then(res => {
      //   // console.log(' :::: ', this.platform)
      //   if (this.platform === 'desktop') {
      //     for (const i of this.fetchSetIpAdmin) {
      //       // admin ip에 등록되있지 않으면 404로 방출
      //       if (this.ip === i.ip) {
      //         // console.log('AdminIp OK !!')
      //         return true
      //       }
      //     }
      //     router.replace('/error-404')
      //   }
      // })
      // await this.$fetchSetIpBlock({
      //   // site: this.$site,
      // }).then(res => {
      //   for (const i of this.fetchSetIpBlock) {
      //     // Blcok ip에 등록되어 있으면 404로 방출
      //     if (this.ip === i.ip) {
      //       router.replace('/error-404')
      //     }
      //   }
      //   return true
      // })
    },
    async validationForm() {
      const success = await this.$refs.loginValidation.validate()
      if (success) {
        try {
          await this.login({
            site: this.$site,
            userid: this.userid,
            password: this.password,
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login Fail',
              icon: 'XIcon',
              variant: 'error',
            },
          })
          console.log('catch error : ', error)
        } finally {
          await this.$SigninLogs({
            site: this.$site,
            userid: this.userid,
            ip: this.ip,
            browser: this.browser,
            browserVer: this.browserVer,
            os: this.os,
            osVer: this.osVer,
            platform: this.platform,
            networkCo: this.networkCo,
            networkType: this.networkType,
            asn: this.asn,
            route: this.route,
            flag: this.flagUrl,
            country: this.country,
            city: this.city,
            latitude: this.latitude,
            longitude: this.longitude,
          })
        }
      }
    },
    async getIpClient() {
      try {
        // const response = await axios.get('https://ipinfo.io/json');
        // const response = await axios.get('http://ip-api.com/json');
        // const response = await axios.get('https://api.ip.pe.kr/json/');
        const response = await axios.get('https://api.ipregistry.co?key=cwmdadv8au7nkq34')

        this.ip = response.data.ip
        this.countryCity = `${response.data.location.country.name}, ${response.data.location.city}`
        this.flagUrl = response.data.location.country.flag.noto
        this.browser = response.data.user_agent.name
        this.browserVer = response.data.user_agent.version
        this.os = response.data.user_agent.os.name
        this.osVer = response.data.user_agent.os.version
        this.platform = response.data.user_agent.device.type
        this.networkCo = response.data.company.name
        this.networkType = response.data.company.type
        this.asn = String(response.data.connection.asn)
        this.route = response.data.connection.route
        this.country = response.data.location.country.name
        this.city = response.data.location.city
        this.latitude = String(response.data.location.latitude)
        this.longitude = String(response.data.location.longitude)

        localStorage.setItem('ip', this.ip)
        // console.log('get info:::', response)
        await this.fetchData()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
